
@import 'google-ads.scss';

html {
	overflow-y: scroll;
}

h1 {
	color: #448fb4;
	font-size: 1.7em;
}

body {
	margin: 10px;
	background-color: #448FB4;
	color: #000000;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 0.8em;
}

.noscript {
	width:100%;
	height:100%; /* will cover the text displayed when javascript is enabled*/
	z-index:100000; /* higher than other z-index */
	position:fixed;
}

.noscript #div100 {
	margin-top: -20px;
	display:block;
	height:100%;
	background-color:white;
	text-align: center;
	padding-top: 100px;
	font-size: 1.3em;
	font-weight: bold;
	line-height: 150%;
}

#wrapper {
	background-color: #e7f4fc;
	width: 760px;
	margin-left: auto;
	margin-right: auto;
}

#header {

}

#menubalk {
	background-color: #448FB4;
}

#content {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 10px;
	line-height: 150%;
}

#footer {
	margin-top: 3px;
	margin-bottom: 10px;
	width: 760px;
	margin-left: auto;
	margin-right: auto;
	text-align: right;
	font-size: 0.8em;
	color: #ffffff;
}

#footer a {
	color: #ffffff;
}

#footer a:hover {
	color: #FE860B;
}

hr {
	color: #448FB4;
	background-color: #448FB4;
	height: 1px;
	border: 0;
}

.hr_large_margin {
	margin-top: 15px;
	margin-bottom: 15px;
}

td {
	font-size: 1.0em;
	line-height: 150%;
}

a {
	color: #357392;
}

a:hover {
	color: #FE860B;
}

#anderegroep {
	font-size: 0.8em;
	height: 20px;
}

.toplogo {
	margin-left: 5px;
	margin-top: 5px;
	margin-bottom: 5px;
	border: 0;
}

#uitloggen {
	font-size: 0.8em;
	height: 20px;
}

.groepnaam {
	height: 32px;
	padding-top: 10px;
	color: #448fb4;
	font-size: 20pt;
	width: 378px;
	overflow: hidden;
}

.groepnaam_kleinfont {
	font-size: 15pt;
}

#menu_table {

}

#menu_table td {
	font-weight: bold;
}

.menu {
	color : #FFFFFF;
}

a.menu:hover {
	color : #FFAA53;
}

.blueborder {
	border-style : solid;
	border-width : 1px;
	border-color : #448FB4;
}

#printbutton {
	border:1px solid #448FB4;
	cursor: pointer;
	width: 178px;
	height: 38px;
	margin-top: 10px;
	margin-bottom: -10px;
}

#printbutton:hover {
	border:1px solid #FE860B;
}

li {
	padding-bottom: 0px;
}

ul {
	margin-top: 5px;
	margin-bottom: 0px;
}

.rennerwit {
	background-color: #FFFFFF;
}

.renner_tr {
	cursor: pointer;

	-moz-user-select: -moz-none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.renner_tr_not_clicked:hover {
	cursor: pointer;
	background-color: #ebebeb;
	color: blue;
}

.geselecteerderenner {
	background-color: #FFF600;
}

.geselecteerderenner:hover {
	color: blue;
}

.inzetopval {
	background-color: #fffbb5;
}

.inzetmelding {
	border:1px solid black;
	background-color:#fffbb5;
	padding:6px;
	margin-top:10px;
	margin-bottom:25px;
}

.opmerking_bovenaan {
	border:1px solid black;
	background-color:#ebebeb;
	padding:6px;
	margin-top:10px;
	margin-bottom:15px;
}

#notificationbutton {
	visibility: hidden;
}

#notificationbutton_confirmed {
	display: none;
}

.button {
	background-color: #448fb4;
	border-radius: 4px;
	color: #fff;
	padding: 4px;
	opacity: 1;
	cursor: pointer;
	text-align: center;

	&:hover {
		transition: opacity .25s ease-out;
		opacity: .7;
	}

	&--notifications {

		@media (max-width:767px) {
			display: block;
			margin-top: 10px;
			padding: 7px;
			font-weight: bold;
		}


	}
}

.forumbanner {
	background-color: #ffffff;
	border:1px solid #448fb4;
	padding: 7px;
	margin-bottom: 10px;
}

.forumbanner a {
	font-weight: normal;
}

#forumbanner_aantalnieuweberichten_renner_invoeren {
	margin-top: 0px;
	margin-bottom: 10px;
	font-style: italic;
	max-width: 700px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

#forumbanner_aantalnieuweberichten {
	margin-top: 10px;
	margin-bottom: 5px;
	font-style: italic;
}

.opvalblok {
	background-color: #ffffff;
	border: 1px solid #448fb4;
	padding: 5px;
	margin-top: 30px;
	margin-bottom: 30px;
	font-weight: normal;
}

.height_filler {

}

.gewone_breedte {
	width: 700px;
}

.gewone_breedte_iets_smaller {
	width: 690px;
}

.niet_te_breed {
	width: 574px;
}

.errorblok {
	color: red;
	background-color: #ffffff;
	border:1px solid #448fb4;
	padding: 6px;
	margin-top: 30px;
	margin-bottom: 30px;
}

a.link-button {
	display: block;
	background-color: #ffffff;
	border: 1px solid #448fb4;
	padding: 8px;
	margin: 15px 0 15px 0;
	font-weight: normal;
	text-align: center;
}

a.invite-new-members, a.change-group-data {
	width: 230px;
}

#multiplegroups_block {
	margin-top: 4px;
}

#multiplegroups_block div {
	margin-bottom: 2px;
}

#multiplegroups_block input[type=checkbox] {
	vertical-align:middle;
	position:relative;
	bottom:1px;
}

.clear {
	clear: both;
}

.echtbelangrijk {
	font-weight: bold;
}

.tableheader {
	color: #FFFFFF;
}

.disclaimer {
	font-size: 0.8em;
}

.tbl_standaard {
	width: 600px;
}

.tbl {
	border: solid #448FB4 1px;
}

.tbl td {
	border: solid #448FB4 1px;
}

.tbl th {
	font-weight: bold;
	color: #ffffff;
	background-color: #448FB4;
	border: solid #448FB4 1px;
	text-align: left;
}

.tbl_td_bold {
	font-weight: bold;
}

.tbl_geen_td_border {
	border: solid #448FB4 1px;
}

.tbl_geen_td_border td {
	border: 0px;
}

.tbl_startnu {
	border: solid #FE860B 1px;
}

.tbl_startnu td {
	border: solid #FE860B 1px;
}

.tbl_nl {
	border: solid #FF8135 1px;
}

.tbl_nl td {
	border: solid #FF8135 1px;
}

.tbl_be {
	border: solid #FF2020 1px;
}

.tbl_be td {
	border: solid #FF2020 1px;
}

.tbl_opval {
	background-color:#ffffff;
	font-weight:bold;
}

.aligntop td {
	vertical-align: top;
}

.formstyle100 {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	width: 100%;
	font-size: 1.0em;
}

.formstyle {
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 1.0em;
}

.onlyprint {
	display: none;
}

.wtform_table {
	width: 600px;
	font-family: Verdana, Helvetica, Arial, sans-serif;
	background-color: #FFFFFF;
	border: 2px solid #448fb4;
}

.wtform_input {
	font-family: Verdana, Helvetica, Arial, sans-serif;
	font-size: 1.0em;
	width: 100%;
	border: 2px solid #448fb4;
}

.wtform_input_narrow {
	font-family: Verdana, Helvetica, Arial, sans-serif;
	font-size: 1.0em;
	border: 2px solid #448fb4;
}

.wtform_error {
	color: red;
}

.wtform_cell_left {
	width: 150px;
	padding: 6px;
}

.wtform_cell_right {
	width: 350px;
	padding: 6px;
}

.wtform_cell_colspan {
	padding: 6px;
}

.wtform_img_tbl {
	border: solid #448fb4 2px;
}

.wtform_small {
	font-size: 0.8em;
}

/*                        */
/* forum                  */
/*                        */

#forum_wrapper {
	margin-top: 10px;
}

#forum_wrapper .wtform_cell_left {
	width: 100px;
}

#forum_wrapper .wtform_cell_right input {
	width: 550px;
}

#forum_aantalnieuweberichten {
	margin-bottom: 20px;
	font-style: italic;
	background-color: #ffffff;
	padding: 5px;
	border: 1px solid #448FB4;
}

.forum_post {
	border: 1px solid #448fb4;
	margin-bottom: 25px;
}

.forum_post_header {
	background-color: #448fb4;
	color: #ffffff;
	padding: 5px;
	font-size: 0.85em;
}

.forum_oud_nieuw {
	width: 100%;
	margin-bottom: 10px;
}

.forum_post_header_naam {
	float: left;
	font-weight: bold;
}

.forum_post_header_naam a {
	color: #ffffff;
}

.forum_post_header_naam a:hover {
	color: #FE860B;
}

.forum_post_header_geplaatst {
	float: right;
}

.forum_post_content {
	padding: 8px;
	color: #000000;
	background-color: #ffffff;
}

.forum_reageer {
	margin-top: 20px;
}

.forum_reageer div {
	color: #000000;
	margin-bottom: 4px;
}

.forum_reageer textarea {
	width: 700px;
	display: block;
	margin-bottom: 10px;
	height: 130px;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 1.0em;
	padding: 8px;
}

.forum_emoji {
	font-size: 1.3rem;
	display: inline-block;
	vertical-align: middle;
	margin-top: -4px;
}

@media (max-width:767px) {
	.forum_emoji {
		font-size: 1.1rem;
	}
}
.controleer_gegevens {
	background-color: #ffffff;
	border: 1px solid #448FB4;
	padding: 10px;
	width: 470px;
}

.ploegnaam {
	color: #666666;
}

.aantal_gekozen_fixed {
	background-color: #ffffff;
	border: 1px solid #448FB4;
	padding: 10px;

	box-shadow: 2px 2px 5px #888;

	width: 200px;

	position:fixed;
	top: 2%;
	right: 2%;

	display: none;

}

.renners_uitgevallen {
	background-color: #fffbb5;
	border: 1px solid #448FB4;
	padding: 10px;
}

#loginform_table input[type=email], #loginform_table input[type=password]{
	padding-right: 0px;
}

.nieuwe_groep_keuze {
	margin-top: 10px;
}

.nieuwe_groep_keuze a {
	display: block;
	text-decoration: none;
	color: #000000;
	border: 1px solid #448fb4;
	padding: 10px;
	margin-bottom: 20px;
	cursor: pointer;
}

.nieuwe_groep_keuze a:hover {
	border-color: #000000;
	background-color: #cae8fa;
}

form.groepsgegevens-wijzigen input {
	font-family:  Verdana, Helvetica, Arial, sans-serif;
	font-size: 1.0em;
}

.tr_link {
	color: #fff;

	&:hover {
		color: #ebebeb;
	}
}

table.groepsgegevens-wijzigen {

	input[type=text] {
		width:430px;
	}

	textarea {
		font-family:  Verdana, Helvetica, Arial, sans-serif;
		font-size: 1.0em;
		width:430px;
		height:200px;
	}

}

table.login {
	width: 350px;
}

.oudeformclass_width_400 {
	width: 400px;
}

.oudeformclass_width_450 {
	width: 450px;
}

.oudeformclass_width_600 {
	width: 600px;
}

.oudeformclass_width_650 {
	width: 650px;
}

.oudeformclass_width_700 {
	width: 700px;
}

.nowrap {
	white-space: nowrap;
}

#topbalk {

	width: 100%;

	.topbalk_l {
		text-align: left;
	}

	.topbalk_r {
		vertical-align: bottom;
		text-align: right;
		width: 99%;
		padding-right:5px;
		padding-bottom:5px;
		padding-top:5px;
	}

	.toplogo {
		width: 371px;
		height: 77px;
	}
}

td.menu_item {
	padding: 4px;
}

td.menu_separator {
	padding: 4px;
	color: #FFAA53;
	font-weight: bold;
}

.mobile_hambuger:focus, .mobile_hambuger:active {
	border: 0;
	outline: 0;
}

.onlymobile {
	display: none;
}

.uitnodigen_link {
	background-color: #ffffff;
	padding: 10px;
	border: 1px solid #448fb4;
	text-align: center;
	width: 500px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}

table.table_homepage {
	width: 700px;
}

.laag_melding {
	width: 560px;
	visibility: hidden;
	position: absolute;
	color: #ffffff;
	line-height: 1.5em;
}

.contactblok {
	border: 1px solid #448fb4;
	background-color: #ffffff;
	padding: 10px;
	width: 500px;
}

.totaaltabel {
	td:nth-child(5) {
		min-width: 50px;
	}
}

table.deelnemers_beheren {
	tr {
		&:hover {
			background-color: #fffbb5;
		}
	}
}

table.ploegsamenstellen {

	td.ploegsamenstellen {

	}

	th {

	}

	.team_name {
		font-weight: bold;
		line-height: 2.5em;
	}

	table.renners_table {
		width: 279px;
	}

	.renner_tr {

		td:nth(1) {
			width: 25px;
		}

		td:nth(2) {
			width: 99%;
		}
	}
}


@media (max-width:767px) {
	/*mobiel*/

	html {
		padding: 0;
		margin: 0;
	}

	body {
		margin: 0;
	}

	h1 {
		line-height: 1.1em;
	}

	.nowrap {
		white-space: normal;
	}

	.onlymobile {
		display: block;
	}

	.list li {
		line-height: 1.7rem;
	}

	#wrapper {
		width: 100%;
		padding: 0;
	}

	#content {
		padding: 5px;
	}

	#footer {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		padding-right: 10px;
	}

	#menubalk {
		display: none;
	}

	.mobiel_streep_boven_menubalk {
		background-color: #448FB4;
		height: 1px;
	}

	#menu_table {
		width: 100%;
	}

	.height_filler {
		height: 600px;
	}

	.contactblok {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
	}

	td.menu_item {
		display: block;
		font-size: 1.1em;
		line-height: 30px;
		padding-left: 10px;
		border-bottom: 1px solid #ffffff;
	}

	td.menu_item a {
		display: block;
		width: 100%;
		font-weight: normal;
		text-decoration: none;
	}

	td.menu_separator {
		padding: 0;
		display: none;
	}

	.overalltable tr td:nth-child(2) {
		max-width: 100px;
		overflow-x: hidden;
	}

	.overalltable tr td:nth-child(3) {
		max-width: 100px;
		overflow-x: hidden;
	}

	.mobile_hambuger {
		position: absolute;
		top: 10px;
		right: 10px;
	    width: 30px;
	    height: 30px;
	    cursor: pointer;
	    background-image: url('https://cdn.tourploeg.nl/pic/hamburgericon.png');
	    background-size: contain;
	    border: 0;
	}

	.controleer_gegevens {
		width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		}

		.laag_melding {
			width: auto !important;
			min-height: 60px;
		}

		.melding {

		}

		.meldingmobiel {
			background-color: #ffffff;
			border: 1px solid #448fb4;
			padding: 5px;
			font-size: 0.8em;
			margin-top: 15px;
			margin-bottom: 15px;
		}

	#topbalk {
		padding: 3px;

		.topbalk_l {
			text-align: center;
			display: block;
		}

		.topbalk_r {
			display: block;
			text-align: left;
		}

		.toplogo {
			display: block;
			margin: 0 0 0 5px;
			width: 159px;
			height: 40px;
		}

		.groepnaam {
			height: auto;
			padding: 0 0 0 5px;
			width: auto;
			font-size: 1.2em;

			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		#anderegroep {
			display: block;
			height: auto;
			padding: 0 0 0 5px;
		}

		#uitloggen {
			display: none;
		}
	}

	td.groepnaam_getoond.multiple_groups {
		display: none !important;
	}

	td.multiple_groups {
		background-color: red;
	}

	.groepnaam.only_one_group {
		display: none;
	}

	.groepnaam.multiple_groups {
		display: block;
	}

	.gewone_breedte {
		width: 100% !important;
	}

	.gewone_breedte_iets_smaller {
		width: 100% !important;
	}

	.oudeformclass_width_400 {
		width: 100%;
	}

	.oudeformclass_width_450 {
		width: 100%;
	}

	.oudeformclass_width_600 {
		width: 100%;
	}

	.oudeformclass_width_650 {
		width: 100%;
	}

	.oudeformclass_width_700 {
		width: 100%;
	}

	.forum_reageer textarea {
		width: 100%;
		padding: 0;
		margin: 0;
	}

	table.login {
		width: 100%;
		input[type=email], input[type=password] {
			width: 80%;
		}
	}

	table.table_homepage {
		width: 100%;
	}

	.niet_te_breed {
		width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}

	table.ploegsamenstellen {

		td.ploegsamenstellen {
			display: block;
			margin: 0;
			padding: 0;
			width: auto;
		}

		th {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		table.renners_table {
			width: 100%;
		}

		.renner_tr {
			width: 100%;

			td {
				line-height: 3em;
			}

			td:nth-child(1) {
				width: 25px;
			}

			td:nth-child(2) {
				width: calc(100% - 25px);
			}
		}
	}

	.uitnodigen_link {
		width: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}


	// new formclass
	table.wtform_table {
	    width: 100%;
	    border: 0;
	}

	.wtform_cell_left {
	    display: block;
	    width: 90%;
	}

	.wtform_cell_right {
	    display: block;
	    margin-top: -5px;
	    margin-bottom: 5px;
	    width: 90%;
	}

	.wtform_cell_right select {
	    width: 100%;
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	}

	.wtform_cell_right input {
	    width: 100%;
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	}

	.wtform_cell_right input[type=radio], .wtform_cell_right input[type=checkbox] {
	    width: 20px;
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	}

	label {
	    display: inline-block;
	    margin: 0 30px 0 0;
	}

	.wtform_input {
	    width: 100%;
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	}

	.wtform_input_narrow {
	    width: 90px !important;
	    -webkit-box-sizing: border-box;
	    -moz-box-sizing: border-box;
	    box-sizing: border-box;
	}




	table.groepsgegevens-wijzigen {

		input[type=text] {
			width: 100%;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		textarea {
			width: 100%;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		td {
			display: block;
			width: 100%;
		}
	}

	.tbl, .tbl_nl, .tbl_be {
		width: 100%;

		th {
			font-weight: normal;
		}

		td {
			// display: block;
		}
	}

	.totaaltabel {
		td:nth-child(2) {
			max-width: 100px;
		}

		td:nth-child(5) {
			min-width: 0;
		}

	}

	.aantal_gekozen_fixed {
		top: 5px;
		right: 45px;
	}

	#printbutton {
		display: none;
	}

}

.rider_allemaaldezelfde {
	color: #878481;
}

.rider_withdrawal {
	color: #c55757;
	text-decoration: line-through;
}

.rider_bijzonder {
	background-color: #fffedb;
}

table[data-role="legend"] {
	.rider_allemaaldezelfde, .rider_withdrawal, .rider_bijzonder {
		font-style: italic;
	}
}

@media print {

	.noprint {
		display: none;
		height: 0px;
		visibility: hidden;
	}

	#printbutton {
		display: none;
		height: 0px;
	}

	.logopic {

	}

	.printheader {
		margin-bottom: 10px;
	}

	.onlyprint {
		display: inline;
	}

	table[data-role="legend"] {
		.rider_withdrawal, .rider_bijzonder {
			font-style: normal;
		}
	}

	.rider_bijzonder {
		font-style: normal;
		color: blue;
	}

	.rider_withdrawal {
		font-style: normal;
	}

	.rider_allemaaldezelfde {
		font-style: italic;
	}

	.print_deelnemerstable {
		page-break-inside: avoid;
	}

	.print_deelnemerstable_totaal tr {
		page-break-inside: avoid;
	}

	.print_deelnemerstable_totaal td {
		page-break-inside: avoid;
	}

	.print_pagebreak {
		page-break-after: always;
	}

	.tbl {
		border: solid #000000 1px;
	}

	.tbl th {
		color: #000000;
		border: solid #000000 1px;
		font-size: 1.1em;
	}

	.tbl td {
		border: solid #000000 1px;
	}

	.tbl_geen_td_border {
		border: solid #000000 1px;
	}

	.tbl_geen_td_border td {
		border: 0px;
	}
}


.tbl {
	td[data-position="13"] {
		transform: rotate(180deg) scaleX(1);
	}
}

.nowrap-on-desktop {
	@media screen and (min-width: 768px) {
		white-space: nowrap;
	}
}
