
.google_adsense_adblock {
	min-height: 280px;
	margin: 15px auto 30px auto;
}

.google_adsense_adblock_label {
	margin: 35px auto 0 auto;
	text-align: center;
	font-style: italic;
	border-bottom: 1px solid #448fb4;
}

#header, #menubalk {
	.google-auto-placed {
		display: none !important;
	}
}

@media (max-width:767px) {
	/*mobiel*/

	/** Hide legacy banner divs */
	td.google_banner {
		display: none;
	}
}
